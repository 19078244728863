import styled from "styled-components"

export const Wrapper = styled.main`
  padding: 0 40px;

  .prev-next-links {
    display: flex;
    justify-content: space-between;
    margin: 40px 0;
    cursor: pointer;
    padding-bottom: 22px;
    border-bottom: 2px solid darkgray;

    a {
      font-family: "fjalla";
      font-size: 21px;
      text-decoration: underline;
    }
  }

  @media (max-width: 1400px) {
    .content {
      flex-direction: column;
      align-items: unset;

      .col-1,
      .col-2 {
        flex: unset;
      }
      .col-1 {
        order: 1;

        h1 {
          margin-top: 40px;
        }
      }
    }
  }
  @media (max-width: 557px) {
    .prev-next-links {
      a {
        font-size: 14px;
      }
    }
  }
`
