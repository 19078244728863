import React, { Component, Fragment } from "react"
import { Link, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltRight, faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons"
import Img from "gatsby-image"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import { Wrapper } from "../components/Style/single-portfolio"

export default class BlogPost extends Component {
  constructor() {
    super()
    this.state = {
      top: 0,
    }
  }

  componentDidMount() {
    if (window.innerWidth > 1400) {
      document.addEventListener("scroll", event => {
        this.setState({ top: event.target.scrollingElement.scrollTop })
      })
    }
  }

  render() {
    const { data, pageContext } = this.props
    const post = data.markdownRemark
    const { previous, next } = pageContext
    const theTitle = post.frontmatter.title
    const theExcerpt = post.excerpt
    const theBackground = post.frontmatter.featuredImage.childImageSharp.fluid

    const { top } = this.state

    return (
      <Fragment>
        <Seo title={theTitle} description={theExcerpt} />

        <Layout type={"single"}>
          <Wrapper>
            <div className="prev-next-links">
              {previous ? (
                <Link to={previous.fields.slug}>
                  <FontAwesomeIcon icon={faLongArrowAltLeft} /> {previous.frontmatter.title}
                </Link>
              ) : (
                <div></div>
              )}
              {next ? (
                <Link to={next.fields.slug}>
                  {next.frontmatter.title} <FontAwesomeIcon icon={faLongArrowAltRight} />{" "}
                </Link>
              ) : (
                <div></div>
              )}
            </div>

            <div className="content">
              <div className="col-1">
                <h1>{theTitle}</h1>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
              </div>
              <div className="col-2">
                <div className="image-section" style={{ marginTop: `${top}px` }}>
                  <Img fluid={theBackground} alt="the project" />
                </div>
              </div>
            </div>
          </Wrapper>
        </Layout>
      </Fragment>
    )
  }
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
